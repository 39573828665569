<template>
  <div class="w-full mb-20" v-if="!showButtons">
    <portal to="popup-request-more-information-revellation">

       <Popup  v-show="showModal" @close="showModal = false" padding_popup="p-0" disableClickOutside container_rounded="rounded-3xl" max_width_model="max-w-4xl">

   <div class="flex items-center  " slot="body">
    <div class="flex flex-col py-8 px-12 sm:px-6 sm:py-4 space-y-6 sm:space-y-3">
        <img src="/images/m6/logo-promy.svg" class=" w-32 h-[33px]" alt="">
         <h2 class="text-3xl font-bold py-2 font-main text-promy-blue-290 sm:text-2xl ">
          Révélation de potentiel
        </h2>
        <p
          class="text-base font-normal  text-black first-letter:capitalize"
         
        >
          Pour nous permettre d’être toujours plus précis sur l’étude de votre
          bien, répondez à quelques questions supplémentaires !
        </p>
        <div class="flex space-x-4">
          <pro-button btn="tertiary" @click="addMoreInformations">
            <span class="py-1 px-3 first-letter:capitalize">Oui</span>
          </pro-button>
          <pro-button btn="quaternary" @click="quitTunnel" >
            <span class="py-1 px-3 first-letter:capitalize">Non</span>
          </pro-button>
        </div>
    </div>
    <img src="/images/m6/popup-chabal.png" class=" w-[26rem] lg:hidden" alt="">
   </div>
     
      </Popup>

     </portal
    >

    <div class="grid grid-cols-2 lg:grid-cols-1 gap-4">
      <button
        @click="navigatePrevious"
        class="sm:text-base bg-promy-blue-50 px-8 py-5 rounded-lg text-promy-green-350 font-semibold hover:bg-promy-blue-100 disabled:bg-promy-gray-100 disabled:text-promy-gray-200 lg:order-2"
      >
        Précédent
      </button>
      <button
       :disabled="goNext || is_loading"
        @click="navigateNext()"
        class="sm:text-base bg-promy-green-350 px-11 py-5 rounded-lg text-white font-semibold hover:bg-promy-green-300 disabled:bg-promy-gray-100 disabled:text-promy-gray-200 lg:order-1"
      >
        <span
          v-if="
            (getStoreStepNumber === 3 && !$route.params.id_revelation) ||
            getStoreStepNumber === 7
          "
        >
          Valider</span
        >
        <span v-else> Suivant </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    goNext: Boolean,
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      id_revelation: null,
      params:{},
      is_loading:false
    }
  },
  mounted(){
  this.params =  this.$route.query.from_europe1 ?  {
      adresse:this.$route.query.adresse,
      email:this.$route.query.email,
      tel:this.$route.query.tel,
      from_europe1:this.$route.query.from_europe1
      } : {}
  },
  methods: {
    quitTunnel() {
      this.showModal = false
      this.$router.push({
        name: this.isLoggedIn ? 'revelations' : 'adresse',
      })
    },
    addMoreInformations() {
      this.showModal = false
      this.$store.commit('Tunnel/SET_STEP_NUMBER', this.getStoreStepNumber + 1)
      this.$router.push({
        path: `/revelation/steps/${this.getStoreStepNumber}/${this.id_revelation}`,
        query: { id_address: this.$route.query.id_address,...this.params },
      })
    },
    navigateNext(force_next_step = false) {
      if (
        ((this.getStoreStepNumber === 3 && !this.$route.params.id_revelation) ||
          this.getStoreStepNumber === 7) &&
        !force_next_step
      ) {
        //submit data
        if (this.$route.params.id_revelation) {
          this.is_loading = true
          this.$http
            .post('grand-publics/reveler-potentiels'+
                (this.isLoggedIn ? '' : '/guest'), {
              ...this.form,
              uuid: this.$route.params.id_revelation,
              from_europe1 : this.$route.query.from_europe1 ? this.$route.query.from_europe1 : null
            })
            .then((response) => {
              this.toast(
                'Révélation de potentiel',
                'Votre demande a bien été modifiée',
                'success',
              )

              this.$router.push({
                name: this.isLoggedIn ? 'revelations' : 'adresse',
              })
              this.is_loading = false
            })
            .catch((error) => {
              this.displayErrorMsg(error)
              this.is_loading = false
            })
        } else {
          this.is_loading = true
          this.$http
            .post(
              'grand-publics/reveler-potentiels' +
                (this.isLoggedIn ? '' : '/guest'),
              {
                ...this.form,
                 from_europe1 : this.$route.query.from_europe1 ? this.$route.query.from_europe1 : null
              },
            )
            .then((response) => {
              this.toast(
                'Révélation de potentiel',
                'La demande a été créée avec succès',
                'success',
              )
              this.id_revelation = response.data.uuid
              this.showModal = true
               this.is_loading = false
            })
            .catch((error) => {
              this.displayErrorMsg(error)
              this.is_loading = false
            })
        }

        return
      }
      this.$store.commit('Tunnel/SET_STEP_NUMBER', this.getStoreStepNumber + 1)
      this.changeRoute()
    },
    navigatePrevious() {
      this.$store.commit('Tunnel/SET_STEP_NUMBER', this.getStoreStepNumber - 1)
      this.changeRoute()
    },
    changeRoute() {

      this.$router.push({
        path: `/revelation/steps/${this.getStoreStepNumber}/${
          this.$route.params.id_revelation ?? ''
        }`,
        query: { id_address: this.$route.query.id_address,...this.params },
      })
    },
    displayErrorMsg(errors) {
      let firstError =
        errors.response.status === 422
          ? Object.values(errors.response.data.errors)[0][0]
          : 'Une erreur est survenue'

      this.toast('Révélation de potentiel', firstError, 'warning')
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
    showButtons() {
      return this.$route.name === 'revelationStep1'
    },

    getStoreStepNumber() {
      return this.$store.getters['Tunnel/GET_STEP_NUMBER']
    },
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .md\:bottom-x {
    bottom: 60%;
  }
}

@media (max-width: 1024px) {
  .lg\:bottom-x {
    bottom: 60%;
  }
}
</style>
