<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <div>
          <div class="font-bold text-2xl mb-4 text-promy-blue-300 sm:text-base">
            Avez-vous déjà fait estimer ce bien par un professionnel ?
          </div>
          <validation-provider
            class="flex flex-col mb-4"
            name="form.informations.is_estimated"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex gap-4">
              <pro-radio
                label="Oui"
                :value="form.informations.is_estimated"
                @change="change($event)"
              >
                Oui
              </pro-radio>
              <pro-radio
                label="Non"
                :value="form.informations.is_estimated"
                @change="change($event)"
              >
                Non
              </pro-radio>
            </div>
            <div class="w-full">
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </div>
          </validation-provider>
          <div
            v-if="form.informations.is_estimated == 'Oui'"
            class="flex flex-col"
          >
            <div
              class="font-bold text-2xl mb-4 text-promy-blue-300 sm:text-base"
            >
              Selon vous, combien vaut votre bien ?
            </div>
            <pro-input
              :placeholder="'Prix'"
              :rules="'required'"
              v-model="form.informations.estimated_price"
              type="Number"
              withPrixUnit
            />
          </div>
        </div>
      </div>
    </template>
    <template #buttons> <Buttons :form="form" :goNext="invalid" /></template>

    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      soldeList: [
        'Oui, dès que possible',
        'Oui, d’ici 3 mois',
        'Oui, d’ici 6 mois',
        'Oui, dans plus de 6 mois',
        'Non, je souhaite juste me renseigner',
      ],
    }
  },
  methods: {
    change(value) {
      this.form.informations.is_estimated = value
    },
  },
}
</script>

<style scoped>
.space-right > button {
  @apply mr-2 mb-2;
}
</style>
